const firebaseConfig = {
    apiKey: "AIzaSyDB54ghuCTxfEmutWjj3Z1q6PPFiU_LEAs",
    authDomain: "tour-alpha.firebaseapp.com",
    projectId: "tour-alpha",
    storageBucket: "tour-alpha.appspot.com",
    messagingSenderId: "410141357722",
    appId: "1:410141357722:web:f2ff833984de306d425123"
};

export default firebaseConfig;
